const langIt = {
  "df-application.tags-filter-placeholder": "Altro ...",
  "df-application.products-search-placeholder": "Cosa stai cercando?",
  "df-application.tags-filter": "Filtra per tag",
  "df-application.reset-products-filters": "Reimposta",
  "df-application.close-filters": "Ok",
  "df-application.category-filter.options.all": "Tutte le categorie",
  "df-application.category-filter.placeholder": "Tutte le categorie",

  "df-router-title-page-home": "Home",
  "df-router-title-page-promotions": "Promozioni",
  "df-router-title-page-news": "News",
  "df-router-title-page-recipes": "Ricette",
  "df-router-title-page-notifications": "Notifiche",
  "df-router-title-page-shopping-list": "Lista della spesa",
  "df-router-title-page-store": "Punto vendita",
  "df-router-title-page-store-selection": "Selezione punto vendita",
  "df-router-title-page-store-change": "Cambio punto vendita",
  "df-router-title-page-account-activation": "Attivazione account",
  "df-router-title-page-club-card": "Carta club",
  "df-router-title-page-newsletter": "Newsletter",
  "df-router-title-page-user-registration": "Registrazione",
  "df-router-title-page-user-profile-edit": "Modifica profilo",
  "df-router-title-page-account-expenses": "Le tue spese",
  "df-router-title-page-account-cashback": "Il tuo saldo cashback",
  "df-router-title-page-account-card": "La tua tessera",
  "df-router-title-page-password-forgotten": "Reimposta password",
  "df-router-title-page-account-coupon": "I tuoi coupon",
  "df-router-title-page-account-coupon-details": "Il tuo coupon",


  "df-page-home.modal.account-activation.title": "Account attivo",
  "df-page-home.modal.account-activation.body": "Il tuo account di accesso è stato correttamente attivato, fai login con le tue credenziali per accedere!",
  "df-page-home.modal.account-activation.ok": "Accedi",
  "df-page-home.modal.account-activation.error.title": "Attivazione fallita",
  "df-page-home.modal.account-activation.error.body": "Non è stato possibile attivare il tuo account.",
  "df-page-home.modal.account-activation.error.ok": "Ok",
  "df-page-home.modal.email-change.title": "Cambio e-mail",
  "df-page-home.modal.email-change.body": "La tua e-mail è stata correttamente modificata",
  "df-page-home.modal.email-change.ok": "Accedi",
  "df-page-home.modal.email-change.error.title": "Cambio e-mail fallito",
  "df-page-home.modal.email-change.error.body": "Non è stato possibile cambiore la tua e-mail.",
  "df-page-home.modal.email-change.error.ok": "Ok",

  "df-header.application-name": "SMT Digital Flyer",
  "df-header.application-description": "Smart digital flyer",
  "df-header.portal.df-modal.store-selection.title": "Seleziona punto vendita",
  "df-header.portal.df-modal.promotion-selection.title": "Seleziona una promozione",
  "df-header.promotions": "Promozioni",
  "df-header.shopping-list": "Lista",
  "df-header.notifications": "Novità",
  "df-header.news": "News",
  "df-header.selex.store-name": "Stai visualizzando il volantino di: {0}",
  "df-header.selex.mobile.store-name": "Volantino di: {0}",

  "df-header-mobile.modal.private-area-mobile.title": "Area personale",

  "df-promotion-card.date-range": "Dal {0} al {1}",

  "df-card-attachment.show": "Visualizza",
  "df-card-attachment.download": "Scarica",

  "df-card-shopping-list.label": "Lista della spesa",
  "df-card-shopping-list.title": "Lista della spesa {0}",
  "df-card-shopping-list.add-to-list": "Aggiungi alla lista",
  "df-card-shopping-list.empty-list": "La lista della spesa è vuota",
  "df-card-shopping-list.delete.title": "Svuota la lista",
  "df-card-shopping-list.delete.body": "Stai per eliminare i prodotti dalla lista della spesa. Continuare?",

  "df-section-attachments.title": "Allegati",

  "df-section-promotions.title.flyer": "Scopri le promozioni",
  "df-section-promotions.title.catalog": "Scopri i cataloghi",
  "df-section-promotions.title.magazine": "Scopri i nostri magazine",

  "df-section-news.title": "Le news",

  "df-section-recipes.title": "Le ricette",

  "df-section-products.title": "I prodotti",
  "df-section-products.filter.products-counter": "1 prodotto trovato | {count} prodotti trovati",
  "df-section-products.products-counter": "Stai visualizzando <strong>{count}</strong> prodotti di {total}",

  "df-store-selection.search": "Cerca",
  "df-store-selection.empty": "Nessun punto vendita trovato",

  "df-app-navigation.home": "Home",
  "df-app-navigation.promotions": "Promozioni",
  "df-app-navigation.news": "News",
  "df-app-navigation.shopping-list": "Spesa",
  "df-app-navigation.list": "Lista",

  "df-product-feautured-card.discount-range": "Valido dal {0} al {1}",
  "df-product-feautured-card.discount-end": "Valido fino al {0}",

  "df-product-datails-data.discount-range": "Valido dal {0} al {1}",
  "df-product-datails-data.discount-end": "Valido fino al {0}",
  "df-product-datails-data.add-shoppin-list": "Aggiungi alla lista della spesa",
  "df-product-datails-data.added-shoppin-list": "Aggiunto alla lista della spesa",
  "df-product-datails-data.points": "{0} punti",
  "df-product-datails-data.urls.ecommerce": "E-commerce",
  "df-product-datails-data.urls.video": "Video",
  "df-product-datails-data.urls.url": "Informazioni",

  "df-product-datails-related-products.title": "Ti potrebbero piacere",

  "df-product-datails-extra-content.ingredients": "Ingredienti",
  "df-product-datails-extra-content.nutritional-values": "Valori nutrizionali",
  "df-product-datails-extra-content.nutritional-values-calculated": "Valori nutrizionali calcolati",
  "df-product-datails-extra-content.allergens": "Allergeni",

  "df-page-promotions.title": "Le promozioni",
  "df-page-promotions.empty-list": "Nessuna promozione presente",

  "df-page-promotion.empty-list": "La ricerca non ha prodotto risultati",
  "df-page-promotion.switch-view.button-products": "Lista",
  "df-page-promotion.switch-view.label-products": "Lista prodotti",
  "df-page-promotion.switch-view.label-flyer": "Volantino PDF",
  "df-page-promotion.button.download-flyer": "Scarica PDF",
  "df-page-promotion.button.download-flyer-banners": "Scarica il volantino in PDF",
  "df-page-promotion.button.download-flyer.close": "Chiudi",

  "df-page-news.title": "Le news",
  "df-page-news.empty-list": "Nessuna notizia presente",

  "df-page-recipes.title": "Le ricette",
  "df-page-recipes.empty-list": "Nessuna ricetta presente",

  "df-page-special.products-counter": "<strong>{count}</strong> prodotti",

  "df-section-links.club-card": "Carta Club",
  "df-section-links.cosi-comodo": "Cosìcomodo",

  "df-page-club-card.change-club-card-number": "Cambia il numero della carta",
  "df-page-club-card.save-club-card-number": "Salva il numero della carta",
  "df-page-club-card.restore-club-card-number": "Annulla",
  "df-page-club-card.scan-club-card-number": "scansiona carta",
  "df-page-club-card.insert-club-card-number": "Inserisci la tua Carta Club",
  "df-page-club-card.club-card-number-error": "Numero di carta non valido",
  "df-page-club-card.name": "Nome",
  "df-page-club-card.surname": "Cognome",

  "df-store-card.store-selection": "Scegli il supermercato",
  "df-store-card.customer-care-label": "Servizio clienti",
  "df-store-card.special-opening": "Aperture straordinarie",
  "df-store-card.special-closure": "Chiusure straordinarie",
  "df-store-card.special-date-single-day": "{0}",
  "df-store-card.special-date-multiple-day": "Dal {0} al {1}",
  "df-store-card.hours-range": "({0}-{1})",
  "df-store-card.additional-hours-range": "({0}-{1} / {2}-{3})",

  "df-page-store.change-store": "Cambia punto vendita",

  "df-page-notifications-list.empty-list": "Non ci sono notifiche",

  "df-card-product.promo-title": "Sottocosto",
  "df-card-product.discount.upu": "1+1 Gratis",
  "df-card-product.free-points": "<span class='df-value'>{0}</span> punti fidelity",
  "df-card-product.cash-points": "o <span class='df-value'>{0}</span> punti e <span class='df-value'>{1}</span>",
  "df-card-product.discount-range": "Valido dal {0} al {1}",
  "df-card-product.discount-range.same-dates": "Valido per il {0}",
  "df-card-product.discount.SCO": "RISPARMI",
  "df-card-product.discount.STC": "SOTTOCOSTO",
  "df-card-product.discount.SOT": "SOTTOCOSTO",
  "df-card-product.discount.DSV": "SOTTOPREZZO",
  "df-card-product.discount.SPF": "SOTTOPREZZO",
  "df-card-product.discount.TZV": "CON CARTA EURONICS",
  "df-card-product.discount.STC.pieces": "{0} pz disponibili",
  "df-card-product.discount.SOT.pieces": "pezzi disponibili: <strong>{0}</strong>",
  "df-card-product.buy-in-shop": "Acquista in negozio",
  "df-card-product.reserve": "Prenota e ritira",
  "df-card-product.buy-online": "Compra online",
  "df-card-product.multiple": "{0} pezzi",

  "df-campaign-card.button-label": "Scopri di più",

  "df-page-recipe.method": "Procedimento",
  "df-page-recipe.ingrediants": "Ingredienti",
  "df-page-recipe.difficulty": "Difficoltà",
  "df-page-recipe.preparation-time": "Durata",
  "df-page-recipe.cooking-time": "Cottura",
  "df-page-recipe.minuts": "{0} min ",
  "df-page-recipe.doses": "Dosi",
  "df-page-recipe.servings": "{0} persone ",

  "df-page-recipe-products.title": "Acquista i prodotti per la ricetta",

  "df-newsletter-page.title": "Newsletter",
  "df-newsletter-page.form.email.label": "Indirizzo e-mail",
  "df-newsletter-page.form.email.placeholder": "Inserisci il tuo Indirizzo e-mail",
  "df-newsletter-page.form.name.label": "Nome",
  "df-newsletter-page.form.lastname.label": "Cognome",
  "df-newsletter-page.form.name.placeholder": "Inserisci il tuo nome",
  "df-newsletter-page.form.lastname.placeholder": "Inserisci il tuo cognome",
  "df-newsletter-page.form.newsletter-terms-condition-acceptance.label": "Ho letto e accetto ",
  "df-newsletter-page.form.newsletter-terms-condition-acceptance.link": "l'informativa sulla privacy",
  "df-newsletter-page.form.errors.required": "Il campo è obbligatorio",
  "df-newsletter-page.form.subscribe": "Iscriviti",
  "df-newsletter-page.form.unsubscribe": "Annulla iscrizione",
  "df-newsletter-page.swal.privacy.title": "Informativa sulla privacy",
  "df-newsletter-page.swal.privacy.confirm": "Accetta",
  "df-newsletter-page.swal.privacy.cancel": "Annulla",
  "df-newsletter-page.subscribe.error-title": "Si è verificato un errore:",
  "df-newsletter-page.unsubscribe.error-title": "Si è verificato un errore:",
  "df-newsletter-page.authenticated.text": "Se sei già titolare di una Fidelity Card puoi gestire la tua iscrizione alla newsletter dalla pagina <a href='/account/modifica-profilo'>Modifica profilo</a> selezionando o deselezionamdo l'opzione \"<b>Acconsento al trattamento dei miei dati personali per la finalità di Marketing Diretto</b>\".",

  "df-newsletter-page.subscribe.successful": "Iscrizione conclusa con successo!",
  "df-newsletter-page.unsubscribe.successful": "Cancellazione conclusa con successo!",
  "df-newsletter-page.home": "Home",

  "df-page-form-mail.submitted.successful": "fatto",
  "df-page-form-mail.home": "Home",
  "df-page-form-mail.form.submit": "Invia",

  "df-form-registration.form.email.label": "Indirizzo e-mail",
  "df-form-registration.form.confirm-password.label": "Conferma password",
  "df-form-registration.form.password.label": "Password",
  "df-form-registration.form.password-confirm.label": "Conferma password",
  "df-form-registration.form.terms-condition-acceptance.label": "Ho letto ",
  "df-form-registration.form.terms-condition-acceptance.link": "l'informativa sulla privacy",
  "df-form-registration.form.terms-club-card-acceptance.label": "Ho letto e accetto ",
  "df-form-registration.form.terms-club-card-acceptance.link": "il Regolamento Carta Club",
  "df-form-registration.form.new-filed": "+ Aggiungi campo",
  "df-form-registration.form.remove-filed": "- Rimuovi campo",
  "df-form-registration.modal.save-from-data.error.title": "Dati non validi",
  "df-form-registration.modal.save-from-data.error.body": "Verificare gli errori in rosso e riprovare",
  "df-form-registration.modal.save-from-data.error.ok": "Ok",
  "df-form-registration.form.button-restore.label": "Reimposta",
  "df-form-registration.swal.card-club.title": "Regolamento Carta Club",
  "df-form-registration.swal.card-club.confirm": "Accetta",
  "df-form-registration.swal.card-club.cancel": "Annulla",

  "df-form-login.form.email.label": "Indirizzo e-mail",
  "df-form-login.form.password.label": "Password",
  "df-form-login.form.remember-me.label": "Rimani collegato",
  "df-form-login.form.login": "Accedi",
  "df-form-login.form.password.forgotten": "Password dimenticata?",
  "df-form-login.form.error.credentials": "Username e/o password non corretti",
  "df-form-login.form.error.pending": "Account non attivo",
  "df-form-login.modal.account-pending.title": "Account non attivo",
  "df-form-login.modal.account-pending.body": "Controlla la tua e-email per attivare l'account",
  "df-form-login.modal.account-pending.ok": "Ok",

  "df-form-password-change.form.old-password.label": "Vecchia password",
  "df-form-password-change.form.new-password.label": "Nuova password",
  "df-form-password-change.form.confirm-password.label": "Conferma nuova password",
  "df-form-password-change.form.submit.label": "Cambia password",
  "df-form-password-change.modal.save.title": "Password modificata",
  "df-form-password-change.modal.save.body": "La tua password è stata correttamente modificata",
  "df-form-password-change.modal.save.ok": "Ok",

  "df-form-delete-account.form.password.label": "Password",
  "df-form-delete-account.form.submit.label": "Elimina account",
  "df-form-delete-account.modal.title": "Account eliminato",
  "df-form-delete-account.modal.body": "L'account è stato eliminato",
  "df-form-delete-account.modal.ok": "Ok",

  "df-form-email-change.form.old-email.label": "Vecchia e-mail",
  "df-form-email-change.form.new-email.label": "Nuova e-mail",
  "df-form-email-change.form.confirm-email.label": "Conferma nuova e-mail",
  "df-form-email-change.form.submit.label": "Cambia e-mail",
  "df-form-email-change.modal.save.title": "Cambio e-mail",
  "df-form-email-change.modal.save.body": "La richiesta è andata a buon fine, a breve riceverai un'e-mail al nuovo indirizzo per confermare l'operazione!",
  "df-form-email-change.modal.save.ok": "Ok",

  "df-private-area.tab.login": "Login",
  "df-private-area.tab.login.description": "Inserisci le tue credenziali per effettuare l'accesso",
  "df-private-area.tab.registration": "Registrazione",
  "df-private-area.tab.registration.description": "Compila il form per registrarti",
  "df-private-area.tab.forgottenPassword": "Recupero password",
  "df-private-area.tab.forgottenPassword.description": "Inserisci qui l'inidirizzo di posta elettronica con cui ti sei registrato",
  "df-private-area.tab.changePassword": "Cambia la tua password",
  "df-private-area.tab.changeEmail": "Cambia la tua e-mail",
  "df-private-area.tab.deleteAccount": "Elimina il tuo account",
  "df-private-area.tab.changePassword.description": "Compila il form per modificare la password",
  "df-private-area.tab.to-personal-area.link": "Annulla",
  "df-private-area.tab.user-data": "Ciao {0}",
  "df-private-area.to-login.label": "Hai già un'account?",
  "df-private-area.to-login.link": "Accedi",
  "df-private-area.to-registration.label": "Non hai un'account?",
  "df-private-area.to-registration.link": "Registrati",
  "df-private-area.log-out.label": "Esci",
  "df-private-area.tab.userCard": "La tua tessera",
  "df-private-area.tab.userExpenses": "Le tue spese",
  "df-private-area.tab.userCoupon": "I tuoi coupon",
  "df-private-area.tab.userCashback": "Il tuo saldo cashback",
  "df-private-area.tab.editProfile": "Aggiorna il tuo profilo",

  "df-form-forgotten-password.form.email.label": "Indirizzo e-mail",
  "df-form-forgotten-password.form.recovery-password": "Recupera password",
  "df-form-forgotten-password.modal.recovery-password.title": "Richiesta completata",
  "df-form-forgotten-password.modal.recovery-password.body": "A breve riceverai un'e-mail con le indicazioni per recuperare la tua password",
  "df-form-forgotten-password.modal.recovery-password.ok": "Ok",

  "df-page-user-registration.modal.save.title": "Registrazione completata",
  "df-page-user-registration.modal.save.body": "La registrazione è andata a buon fine, a breve riceverai un'e-mail per attivare il tuo account!",
  "df-page-user-registration.modal.save.ok": "Ok",
  "df-page-user-registration.page.title": "Registrazione",
  "df-page-user-registration.page.description": "Inserisci i tuoi dati per creare l'account ed effettuare l'accesso.",
  "df-page-user-registration.save.label": "Registrati",

  "df-page-user-profile-edit.page.title": "Modifica profilo",
  "df-page-user-profile-edit.page.description": "Modifica i dati del tuo profilo personale",
  "df-page-user-profile-edit.save.label": "Salva le modifiche",
  "df-page-user-profile.modal.save.title": "Profilo modificato",
  "df-page-user-profile.modal.save.body": "I tuoi dati sono stati correttamente modificati",
  "df-page-user-profile.modal.save.ok": "Ok",

  "df-page-password-forgotten.page.title": "Reimposta password",
  "df-page-password-forgotten.page.description": "Inserisci qui la tua nuova password",
  "df-page-password-forgotten.form.password.label": "Nuova password",
  "df-page-password-forgotten.form.password-confirm.label": "Conferma password",
  "df-page-password-forgotten.form.submit.label": "Reimposta password",
  "df-page-password-forgotten.modal.recovery-password.title": "Password reimpostata",
  "df-page-password-forgotten.modal.recovery-password.body": "La modifica della password è andata a buon fine",
  "df-page-password-forgotten.modal.recovery-password.ok": "Ok",
  "df-page-password-forgotten.modal.recovery-password.error.title": "Richiesta scaduta",
  "df-page-password-forgotten.modal.recovery-password.error.body": "Non è stato possibile reimpostare la password",
  "df-page-password-forgotten.modal.recovery-password.error.ok": "Ok",

  "df-page-account-card.page.title": "La tua tessera",
  "df-page-account-card.page.description": "Utilizza la tessera per le tue spese.",
  "df-page-account-card.page.description-extra": "Dpiù Extra non è attiva per i punti vendita con indicazione \"Affiliato\".",

  "df-page-account-cashback.page.title": "Il tuo saldo cashback",
  "df-page-account-cashback.page.description": "Saldo aggiornato al {0} alle {1}.",

  "df-page-account-coupon.page.title": "I tuoi coupon",
  "df-page-account-coupon.page.subtitle": "Nessun coupon attivo su {1} | 1 coupon attivo su {1} | {0} coupon attivi su {1}",
  "df-page-account-coupon.page.store-validity": "Coupon validi nel punto vendita {0}",
  "df-page-account-coupon.no-club-card": "Inserisci la tua tessera per visualizzare i coupon",
  "df-page-account-coupon.empty-list": "Nessun coupon presente",
  "df-page-account-coupon.club-card": "La tua tessera",

  "df-page-account-coupon-details.empty": "Il coupon desiderato non esiste o è scaduto",

  "df-card-coupon.discount": "Sconto",
  "df-card-coupon.min-money": "Spesa min. {0}",
  "df-card-coupon.max-money": "Spesa max. {0}",
  "df-card-coupon.min-units": "nd | Acquisto min. {0} pezzo | Acquisto min. {0} pezzi",
  "df-card-coupon.max-units": "nd | Acquisto max. {0} pezzo | Acquisto max. {0} pezzi",
  "df-card-coupon.label.active": "attiva",
  "df-card-coupon.label.disactive": "disattiva",
  "df-card-coupon.label.active-coupons": "Attiva i coupon selezionati",
  "df-card-coupon.label.disactive-coupons": "Disattiva i coupon selezionati",
  "df-card-coupon.label.printed": "stampato",
  "df-card-coupon.label.date-to-expire.label": "Scade oggi! | Scade domani | Scade fra {0} giorni",
  "df-card-coupon.label.date-to-expire.date": "Scade il {0}",
  "df-card-coupon.label.terms": "Leggi i ",
  "df-card-coupon.label.terms-link": "termini e condizioni",
  "df-card-coupon.swal.terms.title": "Termini e condizioni",
  "df-card-coupon.swal.terms.confirm": "Chiudi",

  "df-page-account-expenses.page.title": "Le tue spese",
  "df-page-account-expenses.page.description": "Consulta l'elenco delle tue ultime spese",
  "df-page-account-expenses.page.empty-list": "Nessuna spesa effettuata",
  "df-page-account-expenses.page.next-page": "Carica spese precedenti",

  "enum-df-day-of-week.monday": "Lunedì",
  "enum-df-day-of-week.tuesday": "Martedì",
  "enum-df-day-of-week.wednesday": "Mercoledì",
  "enum-df-day-of-week.thursday": "Giovedì",
  "enum-df-day-of-week.friday": "Venerdì",
  "enum-df-day-of-week.saturday": "Sabato",
  "enum-df-day-of-week.sunday": "Domenica",

  "enum-df-newsletter-subscribe-status.MISSING_FIELDS": "Verificare i campi obbligatori e riprovare",
  "enum-df-newsletter-subscribe-status.NO_API_KEY": "Chiave API assente",
  "enum-df-newsletter-subscribe-status.INVALID_API_KEY": "Chiave API non valida",
  "enum-df-newsletter-subscribe-status.INVALID_EMAIL_ADDRESS": "Indirizzo e-mail non valido",
  "enum-df-newsletter-subscribe-status.ALREADY_SUBSCRIBED": "Sei già iscritto alla newsletter",
  "enum-df-newsletter-subscribe-status.BOUNCED_EMAIL_ADDRESS": "Il tuo indirizzo e-mail è stato bloccato",
  "enum-df-newsletter-subscribe-status.SUPPRESSED_EMAIL_ADDRESS": "Il tuo indirizzo e-mail è stato bloccato",
  "enum-df-newsletter-subscribe-status.INVALID_LIST_ID": "Newsletter non trovata",
  "enum-df-newsletter-subscribe-status.TRUE": "Iscrizione conclusa con successo",
  "enum-df-newsletter-subscribe-status.FALSE": "Si prega di riprovare più tardi",

  "enum-df-newsletter-unsubscribe-status.MISSING_FIELDS": "Verificare i campi obbligatori e riprovare",
  "enum-df-newsletter-unsubscribe-status.INVALID_EMAIL_ADDRESS": "Indirizzo e-mail non valido",
  "enum-df-newsletter-unsubscribe-status.EMAIL_ADDRESS_NOT_FOUND": "Indirizzo e-mail non trovato",
  "enum-df-newsletter-unsubscribe-status.TRUE": "Cancellazione  conclusa con successo",
  "enum-df-newsletter-unsubscribe-status.FALSE": "Si prega di riprovare più tardi",

  "df-measure-unit.kg.label": "al KG",
  "df-measure-unit.hg.label": "all'etto",
  "df-measure-unit.l.label": "al litro",
  "df-special-price": "Prezzo<br />speciale",
  "df-special-price-inline": "Prezzo speciale",

  "dfUserProfileUpdateForm-NotNull": "Il campo è obbligatorio",
  "dfUserProfileUpdateForm-LowerThanNumberMinValue": "Il valore inserito non è valido",
  "dfUserProfileUpdateForm-AgeLow": "Per registrarsi occorre essere maggiorenne",

  "dfUserRegistrationForm-NotNull": "Il campo è obbligatorio",
  "dfUserRegistrationForm-NotAvailable": "Il valore inserito è già utilizzato",
  "dfUserRegistrationForm-Wrong": "Il valore inserito è errato",
  "dfUserRegistrationForm-password-Pattern": "La password deve contenere da 8 a 20 caratteri tra cui lettere minuscole, maiuscole e numeri",
  "dfUserRegistrationForm-Pattern": "Il valore inserito è errato",
  "dfUserRegistrationForm-IwEmail": "Il valore inserito non è un indirizzo e-mail valido",
  "dfUserRegistrationForm-privacyConsent-Mandatory": "Per registrarsi è necessario accettare l'Informativa sulla privacy",
  "dfUserRegistrationForm-clubCardConsent-Mandatory": "Per registrarsi è necessario accettare il Regolamento Carta Club",
  "dfUserRegistrationForm-AgeLow": "Per registrarsi occorre essere maggiorenne",
  "dfUserRegistrationForm-NotMatchTaxCode": "Il valore non corrisponde ai dati inseriti",
  "dfUserRegistrationForm-GreaterThanNumberMaxValue": "Il valore è superiore al massimo consentito",
  "dfUserRegistrationForm-LowerThanNumberMinValue": "Il valore è inferiore al massimo consentito",

  "dfUserForgottenPasswordForm-NotFound": "Indirizzo e-mail non trovato",
  "dfUserForgottenPasswordForm-IwEmail": "Indirizzo e-mail non valido",
  
  "dfUserForgottenPasswordResetForm-Pattern": "La password inserita non è valida",
  "dfUserForgottenPasswordResetForm-NotNull": "Il campo è obbligatorio",
  "dfUserForgottenPasswordResetForm-Wrong": "La password è errata",

  "dfUserDeleteAccountForm-Pattern": "La password inserita non è valida",
  "dfUserDeleteAccountForm-NotNull": "Il campo è obbligatorio",
  "dfUserDeleteAccountForm-Wrong": "La password è errata",

  "dfUserCurrentPasswordChangeForm-NotNull": "Il campo è obbligatorio",
  "dfUserCurrentPasswordChangeForm-Wrong": "La password è errata",
  "dfUserCurrentPasswordChangeForm-Pattern": "La password inserita non è valida",
  "dfUserCurrentPasswordChangeForm-Unchanged": "La nuova password deve essere diversa da quella attuale",

  "df-form-password-forgotten.errors.generic": "Non è stato possibile recuperare la password. Verificare l'Indirizzo e-mail",

  // ss-page-error
  "df-page-error.title": "Errore {0}",
  "df-page-error.error-type": "Si è verificato un errore {0}",
  "df-page-error.error-type.403": "Non disponi delle autorizzazioni per accedere alla risorsa",
  "df-page-error.error-type.404": "La risorsa non è disponibile",
  "df-page-error.error-type.500": "Si è verificato un errore del server",
  "df-page-error.error-type.internal_error": "Si è verificato un errore interno",
  "df-page-error.go-to-home": "Clicca sul pulsante per tornare alla homepage",

  "df-form.password.obfuscated-button.show": "Mostra password",
  "df-form.password.obfuscated-button.hide": "Nascondi password",

  "dfUserCurrentEmailChangeForm-NotNull": "Il campo è obbligatorio",
  "dfUserCurrentEmailChangeForm-NotAvailable": "Il valore inserito è già utilizzato",
  "dfUserCurrentEmailChangeForm-IwEmail": "Il valore inserito non è un indirizzo e-mail valido",
  "dfUserCurrentEmailChangeForm-Wrong": "Il valore inserito è errato",
  "dfUserCurrentEmailChangeForm-Unchanged": "La nuova e-mail deve essere differente dalla quella corrente",
};

export default langIt;
